import React from 'react'
import { useState, createContext, useCallback, ReactNode } from "react"
import { appendScript } from "../assets/helper/appendScript"

export type ExternalScriptsContextData = {
  isRecaptchaScriptsLoaded: boolean
  handleSetRecaptcha: (isLoaded: boolean) => void
  isPRClientAndJQueryScriptsLoaded: boolean
  handleLoadingPRClientAndJQueryScripts: (isLoaded: boolean) => void
  loadPestRoutesClient: () => void
}

interface ExternalScriptsProviderProps {
  children: ReactNode
}

const ExternalScriptsContext = createContext<ExternalScriptsContextData>({} as ExternalScriptsContextData)

const ExternalScriptsProvider = ({ children }: ExternalScriptsProviderProps) => {
  const [isRecaptchaScriptsLoaded, setIsRecaptchaScriptsLoaded] = useState(false) //Recaptcha in FormBuilder

  const [
    isPRClientAndJQueryScriptsLoaded,
    setIsPRsClientAndJQueryScriptsLoaded,
  ] = useState(false) // PR = pestroutes

  const handleLoadingPRClientAndJQueryScripts = useCallback((isLoaded: boolean) => {
    setIsPRsClientAndJQueryScriptsLoaded(isLoaded)
  }, [])

  const loadPestRoutesClient = useCallback(() => {
    if (isPRClientAndJQueryScriptsLoaded) return

    appendScript({
      id: "jquery-3.6.0.min.js",
      scriptToAppend: "https://code.jquery.com/jquery-3.6.0.min.js",
      isAsync: false,
    })

    appendScript({
      id: "pestroutesClient.js",
      scriptToAppend:
        "https://lmk.pestroutes.com/resources/js/lobster/pestroutesClient.js",
      isAsync: true,
    })
  }, [isPRClientAndJQueryScriptsLoaded])

  const handleSetRecaptcha = useCallback((isLoaded: boolean) => {
    setIsRecaptchaScriptsLoaded(isLoaded)
  }, [])

  return (
    <ExternalScriptsContext.Provider value={{
        isRecaptchaScriptsLoaded,
        handleSetRecaptcha,
        isPRClientAndJQueryScriptsLoaded,
        handleLoadingPRClientAndJQueryScripts,
        loadPestRoutesClient
      }}
    >
      {children}
    </ExternalScriptsContext.Provider>
  )
}

export { ExternalScriptsProvider, ExternalScriptsContext}
