import React from "react"
import { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ColorAreasSettingsContextData, ColorAreasSettingsProviderProps, GraphQLContextData, ColorArea } from "./colorAreasSettings-types"

const ColorAreasSettingsContext = createContext<ColorAreasSettingsContextData>({} as ColorAreasSettingsContextData)

type CssVariableColors = {
  primary: "fr-primary-overwrite"
  primary_alt: "fr-primaryAlt-overwrite"
  secondary: "fr-secondary-overwrite"
  secondary_alt: "fr-secondaryAlt-overwrite"
  accent: "fr-accent-overwrite"
  accent_alt: "fr-accentAlt-overwrite"
  gray: "fr-link-overwrite"
}

const COLOR_CSS_VARIABLE: CssVariableColors = {
  primary: "fr-primary-overwrite",
  "primary_alt": "fr-primaryAlt-overwrite",
  secondary: "fr-secondary-overwrite",
  "secondary_alt": "fr-secondaryAlt-overwrite",
  accent: "fr-accent-overwrite",
  "accent_alt": "fr-accentAlt-overwrite",
  gray: "fr-link-overwrite"
}

const ColorAreasSettingsProvider = ({ children }: ColorAreasSettingsProviderProps) => {
  const data = useStaticQuery<GraphQLContextData>(getColorAreasSettinsData)

  const { 
    image_banner_color_overlay,
    text_banner_background_color,
    nav_tile_color_overlay,
    side_nav_background_color
  } = data.kontentItemSettings?.elements

  const getCSSVariableColor = (color: ColorArea | "") => {
    if (!color) return color
    return COLOR_CSS_VARIABLE[color]
  }

  const imageBannerColorOverlay = image_banner_color_overlay?.value[0]?.codename ?? ""
  const textBannerBackgroundColor = text_banner_background_color?.value[0]?.codename ?? ""
  const navTileColorOverlay = nav_tile_color_overlay?.value[0]?.codename ?? ""
  const sideNavBackgroundColor = side_nav_background_color?.value[0]?.codename ?? ""
  
  return (
    <ColorAreasSettingsContext.Provider
      value={{
        imageBannerColorOverlay: getCSSVariableColor(imageBannerColorOverlay),
        textBannerBackgroundColor: getCSSVariableColor(textBannerBackgroundColor),
        navTileColorOverlay: getCSSVariableColor(navTileColorOverlay),
        sideNavBackgroundColor: getCSSVariableColor(sideNavBackgroundColor),
      }}
    >
      {children}
    </ColorAreasSettingsContext.Provider>
  )
}

export const getColorAreasSettinsData = graphql`
  query ColorAreasSettingsContext {
    kontentItemSettings {
      elements {
        image_banner_color_overlay {
          value {
            codename
          }
        }
        text_banner_background_color {
          value {
            codename
          }
        }
        nav_tile_color_overlay {
          value {
            codename
          }
        }
        side_nav_background_color {
          value {
            codename
          }
        }
      }
    }
  }
`

export { ColorAreasSettingsProvider, ColorAreasSettingsContext }
