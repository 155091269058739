import React, { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  GeneralSettingsContextData,
  GeneralSettingsProviderProps,
  GraphQLContextData,
} from "./generalSettings-types"
import { KontentFormStubBannerFormatted } from "../../../components/form-stub/formStubBanner-types"

const GeneralSettingsContext = createContext<GeneralSettingsContextData>(
  {} as GeneralSettingsContextData
)

const GeneralSettingsProvider = ({
  children,
}: GeneralSettingsProviderProps) => {
  const data = useStaticQuery<GraphQLContextData>(getEnvs)

  const { siteUrl, recapKey, blogParent, domainVerification } = data?.nodeEnvs

  const {
    company_name,
    enable_alert_banner,
    alert_banner,
    custom_css,
    custom_css_code,
    custom_cta_text,
    site_name,
    social_media,
    footer_background_image,
    global_form_stub_banner,
    offices,
    scripts,
    use_phone_number_not_tracking_number,
    custom_tracking_number_modal_heading,
    custom_tracking_number_cta_button_text,
    footer_additional_information,
  } = data.kontentItemSettings?.elements

  const companyName = company_name?.value! ?? ""
  const formalCompanyName = site_name?.value! ?? ""
  const customCTAText = custom_cta_text?.value! ?? ""
  const customCSSUrl = custom_css?.value[0]?.url! ?? ""
  const isAlertEnabled =
    enable_alert_banner?.value[0]?.codename === "yes" ? true : false

  const alertBanner = alert_banner?.value[0] && {
    id: alert_banner?.value[0].id,
    alertText: alert_banner?.value[0]?.elements?.alert_text?.value ?? "",
    backgroundColor:
      alert_banner?.value[0]?.elements?.background_color?.value ?? "",
    linkUrl: alert_banner?.value[0]?.elements?.link?.value ?? "",
    iconCodeName:
      alert_banner?.value[0]?.elements?.icon?.value[0]?.codename ?? "",
  }

  const socialMedia =
    social_media?.modular_content?.map(socialMediaItem => {
      return {
        id: socialMediaItem.id,
        linkUrl: socialMediaItem.elements?.link?.value! ?? "",
        mediaOutlet:
          (socialMediaItem.elements?.social_media_outlet?.value! &&
            socialMediaItem.elements?.social_media_outlet?.value[0]
              ?.codename!) ||
          "custom",
        customOutletName: socialMediaItem.elements?.custom_outlet?.value! ?? "",
        customIcon: socialMediaItem.elements?.custom_icon?.value! ?? "",
      }
    }) ?? []

  const customTrackingNumberCtaButtonText =
    custom_tracking_number_cta_button_text?.value! ?? ""
  const customTrackingNumberModalHeading =
    custom_tracking_number_modal_heading?.value! ?? ""
  const usePhoneNumberNotTrackingNumber =
    use_phone_number_not_tracking_number?.value[0]?.codename === "yes"
  const trackingNumbers =
    offices?.modular_content
      ?.map(officeItem => {
        return {
          id: officeItem.id,
          phoneNumber: officeItem.elements?.phone_number?.value! ?? "",
          label: officeItem.elements?.tracking_number_label?.value! ?? "",
          trackingNumber: officeItem.elements?.tracking_number?.value! ?? "",
          svgIcon: officeItem.elements?.tracking_number_svg_icon?.value! ?? "",
        }
      })
      .filter(
        office =>
          office.hasOwnProperty("id") &&
          (usePhoneNumberNotTrackingNumber
            ? office?.phoneNumber
            : office?.trackingNumber)
      ) ?? []

  const formattedOffices =
    offices?.modular_content?.map(officeItem => {
      return {
        id: officeItem.id,
        name: officeItem.elements?.name?.value! ?? "",
        address_line_1: officeItem.elements?.address_line_1?.value! ?? "",
        address_line_2: officeItem.elements?.address_line_2?.value! ?? "",
        city: officeItem.elements?.city?.value! ?? "",
        state:
          officeItem.elements?.state__state_selector?.value[0]?.name! ?? "",
        zip: officeItem.elements?.zip?.value! ?? "",
        phone: officeItem.elements?.phone_number?.value! ?? "",
        email: officeItem.elements?.office_email?.value! ?? "",
        priceRange: officeItem.elements?.price_range?.value! ?? "",
        simpleOfficeHours:
          officeItem.elements?.simple_office_hours?.value! ?? "",
        simpleServiceHours:
          officeItem.elements?.simple_service_hours?.value! ?? "",
        businessHours: officeItem.elements?.business_hours?.value! ?? [],
      }
    }) ?? []

  const footerBackgroundImage = footer_background_image?.value[0]

  const globalFormStubBanner: KontentFormStubBannerFormatted =
    (global_form_stub_banner?.value[0]?.elements && {
      heading: global_form_stub_banner.value[0].elements.heading?.value || "",
      subheading:
        global_form_stub_banner.value[0].elements.subheading?.value || "",
      button:
        global_form_stub_banner.value[0].elements.button.value[0].elements,
      icon: global_form_stub_banner.value[0].elements.icon.value[0],
      fields:
        global_form_stub_banner.value[0].elements.fields.value[0]?.elements ||
        "",
    }) ??
    ({} as KontentFormStubBannerFormatted)

  const footerAdditionalInformation = footer_additional_information?.value

  return (
    <GeneralSettingsContext.Provider
      value={{
        siteUrl,
        recapKey,
        blogParent,
        domainVerification,
        companyName,
        formalCompanyName,
        customCTAText,
        customCSSUrl,
        socialMedia,
        isAlertEnabled,
        alertBanner,
        footerBackgroundImage,
        globalFormStubBanner,
        trackingNumbers,
        formattedOffices,
        scripts,
        customTrackingNumberModalHeading,
        usePhoneNumberNotTrackingNumber,
        customTrackingNumberCtaButtonText,
        footerAdditionalInformation,
      }}
    >
      {children}

      {custom_css_code?.value && <style> {custom_css_code?.value} </style>}
    </GeneralSettingsContext.Provider>
  )
}

export const getEnvs = graphql`
  query GeneralSettingsContext {
    nodeEnvs {
      siteUrl
      recapKey
      blogParent
      domainVerification
    }
    kontentItemSettings {
      elements {
        company_name {
          value
        }
        site_name {
          value
        }
        custom_cta_text {
          value
        }
        enable_alert_banner {
          value {
            codename
          }
        }
        custom_tracking_number_cta_button_text {
          value
        }
        custom_tracking_number_modal_heading {
          value
        }
        use_phone_number_not_tracking_number {
          value {
            codename
          }
        }
        alert_banner {
          ...AlertBannerFragment
        }
        social_media {
          modular_content {
            ...SocialMediaLinkFragment
          }
        }
        custom_css {
          value {
            url
          }
        }
        custom_css_code {
          value
        }
        scripts {
          modular_content {
            ... on kontent_item_scripts__copy_ {
              elements {
                script {
                  value
                }
                src_value {
                  value
                }
              }
            }
          }
        }
        footer_background_image {
          value {
            ...KenticoAssetElementFragment
          }
        }
        global_form_stub_banner {
          value {
            ...FormStubBannerFragment
          }
        }
        footer_additional_information {
          ...RichTextContentFragment
        }

        offices {
          modular_content {
            id
            ...OfficeFragment
          }
        }
      }
    }
  }
`
export const officeFragment = graphql`
  fragment OfficeFragment on kontent_item_office {
    id
    elements {
      tracking_number_label {
        value
      }
      tracking_number {
        value
      }
      tracking_number_svg_icon {
        value
      }
      office_email {
        value
      }
      name {
        value
      }
      address_line_1 {
        value
      }
      address_line_2 {
        value
      }
      city {
        value
      }
      state__state_selector {
        value {
          name
        }
      }
      zip {
        value
      }
      phone_number {
        value
      }
      price_range {
        value
      }
      simple_office_hours {
        value
      }
      simple_service_hours {
        value
      }
      business_hours {
        value {
          ...DayOfTheWeekFragment
        }
      }
    }
  }
`

export const dayOfTheWeekFragment = graphql`
  fragment DayOfTheWeekFragment on kontent_item_day {
    id
    elements {
      day_of_the_week {
        value {
          name
        }
      }
      close {
        value
      }
      open {
        value
      }
      closed{
        value{
          codename
        }
      }
    }
  }
`

export { GeneralSettingsProvider, GeneralSettingsContext }
