import React from "react"
import { useState, createContext, useCallback, ReactNode } from "react"

type FormFieldsContextData = {
  fullName: string
  setName: (name: string) => void
  email: string
  setEmailAddress: (emailAddress: string) => void
}

interface FormFieldsProviderProps {
  children: ReactNode
}

const FormFieldsContext = createContext<FormFieldsContextData>({} as FormFieldsContextData)

const FormFieldsProvider = ({ children }: FormFieldsProviderProps) => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")

  const setName = useCallback(name => {
    setFullName(name)
  }, [])

  const setEmailAddress = useCallback(emailAddress => {
    setEmail(emailAddress)
  }, [])

  return (
    <FormFieldsContext.Provider
      value={{
        fullName,
        setName,
        email,
        setEmailAddress,
      }}
    >
      {children}
    </FormFieldsContext.Provider>
  )
}

export { FormFieldsProvider, FormFieldsContext }
