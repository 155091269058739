module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Baxter Lawn","short_name":"Demo Baxter Lawn","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/placeholder-icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"7e5cc7174c8813e0075beb9b59a0c35b"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://baxter-lawn.lobstermarketing.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../../gatsby-theme-baxter/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"b77dc86b-8aae-000c-c58b-07b81a9f3ecc","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImUyYjc3ZDNmYTM4NTQ3YzVhMzRiYjE5NTEwOTNmY2JjIiwNCiAgImlhdCI6ICIxNjQ0OTQ1OTk3IiwNCiAgImV4cCI6ICIxOTkwNTQ1OTk3IiwNCiAgInZlciI6ICIxLjAuMCIsDQogICJwcm9qZWN0X2lkIjogImI3N2RjODZiOGFhZTAwMGNjNThiMDdiODFhOWYzZWNjIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0._YeeyiO9sENPT8M86eIgX3-cPUIdpklpDmoiw07FL7g","usePreviewUrl":"false","siteUrl":"https://baxter-lawn.lobstermarketing.com","title":"Demo Baxter Lawn","description":"Demo Baxter Lawn","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","whereWeServicePageSlug":"where-we-service","whereWeServiceBreadcrumbLabel":"Where We Service"},
    }]
