import { NavigationItem, NavigationList } from "./navigation-types"

export const getFormattedNavigation = (navigationItems: NavigationItem[]): NavigationList => {
  const navigationList = navigationItems.map(navItem => {
    const childPages = navItem?.elements?.child_page?.value?.map(childPage => {
      return {
        id: childPage.id,
        linkText: childPage?.elements?.link_text?.value! ?? "",
        externalLink: childPage?.elements?.external_link?.value! ?? "",
        slug: childPage?.elements?.page?.value[0]?.elements?.slug?.value! ?? "",
        svg_icon: childPage?.elements?.svg_icon?.value! ?? "",
      }
    }) ?? []
    return {
      id: navItem.id,
      childPages,
      externalLink: navItem?.elements?.external_link?.value! ?? "",
      linkText: navItem?.elements?.link_text?.value! ?? "",
      slug: navItem?.elements?.page?.value[0]?.elements?.slug?.value! ?? "",
    }
  })

  return navigationList
}
